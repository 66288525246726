.media {
  position: relative;
  display: flex;
  max-width: 100%;
  width: 100%; 
  overflow: hidden;
  z-index: 1;
  
  video, img { 
    position: relative;     
    width: 100%; 
    height: auto;
  } 


  &[class~="media-align-left"] { margin-left: 0; margin-right: auto;}
  &[class~="media-align-center"] { margin-left: auto; margin-right: auto; }
  &[class~="media-align-right"] { margin-left: auto; margin-right: 0; }

  &[class~="media-rounded-none"] { border-radius: 0!important; }
  &[class~="media-rounded-normal"] { border-radius: var(--rounded)!important;  }
  &[class~="media-rounded-large"] {  border-radius: var(--rounded-lg)!important; }

  &[class~="media-ratio-1x1"] { height: 0!important; padding-top: 100%; }
  &[class~="media-ratio-4x3"] { height: 0!important; padding-top: 75%; }
  &[class~="media-ratio-16x9"] { height: 0!important; padding-top: 56.25%; }
  &[class~="media-ratio-21x9"] { height: 0!important; padding-top: 42.86%; }
  &[class~="media-ratio-3x2"] { height: 0!important; padding-top: 66%; }
  &[class~="media-ratio-5x4"] { height: 0!important; padding-top: 80%; }
  &[class~="media-ratio-16x10"] { height: 0!important; padding-top: 62.5%; }
  &[class~="media-ratio-3x1"] { height: 0!important; padding-top: 33%; }
  &[class~="media-ratio-9x16"] { height: 0!important; padding-top: 177.78%; }
  &[class~="media-ratio-4x5"] { height: 0!important; padding-top: 120%; }
  &[class~="media-ratio-2x3"] { height: 0!important; padding-top: 155%; }



  &[class*="media-ratio-"] {
    video, img { 
      position: absolute; 
      top: 0; 
      left: 0; 
      width: 100%; 
      height: 100%;
      object-fit: cover;
    } 
  }
}