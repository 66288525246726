.inputWrap {
  display: flex;
  flex-direction: column;
  position:relative;
  width: 100%;
  min-width: 175px;
  margin-bottom: 1rem;
  textarea {    
    width: 100%!important;
    max-width: 100%!important;
    border: 1px solid var(--color-body, #000);    
    border-radius: var(--rounded);    
    padding: 9px 14px;   
    background-color: transparent;
    &:focus {
      box-shadow: 0px 0px 0px 2px var(--color-primary, #000);
    }
  }  

  &[class~="error-input"] input {
    box-shadow: 0px 0px 0px 1px var(--color-danger);
  }

  &[class~="input-lg"] input {
    font-size: 1.35rem;    
    padding: 14px 30px;
  }

  &[class~="input-lg"] :local {
    .error {
      padding: 3px 30px;
    }
  }
}

.labelWrap {
  margin-bottom: 10px;  
  small {
    font-style: italic; font-size: .75rem;
  }
  label {
    margin-bottom: 0;
    display: flex;
    font-weight: 500;
    font-size: .875rem;
  }
}

.error {
  padding: 3px 14px; 
  color: var(--color-danger);
  font-weight: 500;
  line-height: 1;
}