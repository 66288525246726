
.navWrapper {
  position: relative; z-index: 2044;

  .navbar { 
    display: none; position: fixed; z-index: 2044; top: 0;left: 0; width: 100%; background: rgb(244, 242, 234,.1); backdrop-filter: blur(4px);  

    .emblem {flex: 0 0 auto; display: inline-block; padding: .585rem .3rem .585rem .5rem; text-align: center; color: var(--color-primary); border: 1px solid var(--color-primary); border-radius: 100%; }

    .navBarWrap {
      display: flex; justify-content: center;
    }
    .navbarLinks {
      padding: .5rem 0; list-style: none; display: flex; align-items: center; text-align: center; gap: 30px 60px;

      .navlink {
        display: block; cursor: pointer; margin: 0;
        &:hover { cursor: pointer; opacity: .5; }
      }
    }

    @media (min-width: 992px) { 
      display: block;
    }
  }
}