  

.toggleWrapper {
  display: inline-flex;
  background-color: var(--color-body-secondary, #fff)!important;
  border: 1px solid var(--color-body, #000)!important; 
  border-radius: 4px!important;
  overflow: hidden;
  cursor: pointer;
  width: auto;
  user-select: none;
  &:focus {
    box-shadow: 0px 0px 0px 2px var(--color-primary, #000);
  }
}

.labelTrue {
  padding: 5px 15px;
  flex-grow: 1;
  text-align: center;
  user-select: none;
  &.active {
    background-color: var(--color-success, #008000); 
    color: var(--color-body-secondary, #fff);
  }
}

.labelFalse {
  padding: 5px 15px;
  flex-grow: 1;
  text-align: center;
  user-select: none;
  &.active {
    background-color: var(--color-danger, #ff0000); 
    color: var(--color-body-secondary, #fff);
  }
}

.inputWrap {
  display: block;
  position:relative;
  max-width: 100%;
  margin-bottom: 1rem;
}

.labelWrap {
  margin-bottom: 10px;  
  small {
    font-style: italic; font-size: .75rem;
  }
  label {
    margin-bottom: 0;
    display: flex;
    font-weight: 500;
    font-size: .875rem;
  }
}

.error {
  padding: 3px 14px; 
  color: var(--color-danger);
  font-weight: 500;
  line-height: 1;
}