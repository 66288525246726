@import "../../variables";

 
.link {
  display: inline-block;   
}

.btn {
  font-size: 1rem;
  position: relative;
  text-align: center;
  max-width: 100%; 
  display: inline-flex;
  justify-content:center;
  align-items:center;
  padding: 8px 14px;
  border-radius: var(--rounded);  
  border: none;    
  line-height: 1;
  text-wrap: wrap;
  color: var(--color-body, #000);
  background: transparent;
  text-decoration: none!important;
  cursor: pointer;  
  @media (min-width: 992px) {
    padding: 10px 16px;
  } 
}

.btn-lg {
  font-size: 1.35rem;
  padding: 16px 30px;
  border-radius: var(--rounded-lg);

  @media (max-width: 991px) { 
    .btn-lg {
      font-size: 1.1rem;
      padding: 12px 18px;
    }
  }
}

.btn-plain {
  color: var(--color-body, #000);
  font-weight: inherit;
  box-shadow: none;
  border: none;
  padding: 0;
  border-radius: 0;
}  

.btn-ltplain {
  color: var(--color-body-secondary,#fff);
  font-weight: inherit;
  box-shadow: none;
  border: none;
  padding: 0;
}  

.btn-primary {
  color: var(--color-body-secondary, #fff);
  background-color: rgba($color_primary, 1); 
  &:hover {    
    background-color: lighten(rgba($color_primary, 1), 13%);    
  }
  &:active {    
    background-color: lighten(rgba($color_primary, 1), 19%);    
  }
}

.btn-secondary {  
  color: var(--color-body-secondary, #fff);
  background-color: rgba($color_secondary, 1);
  &:hover {    
    background-color: lighten(rgba($color_secondary, 1), 13%);    
  }
  &:active {    
    background-color: lighten(rgba($color_secondary, 1), 19%);    
  }
}

.btn-success {  
  color: var(--color-body-secondary, #fff);
  background-color: rgba($color_success, 1);
  &:hover {    
    background-color: lighten(rgba($color_success, 1), 13%);    
  }
  &:active {    
    background-color: lighten(rgba($color_success, 1), 19%);    
  }
}

.btn-danger {  
  color: var(--color-body-secondary, #fff);
  background-color: rgba($color_danger, 1);
  &:hover {    
    background-color: lighten(rgba($color_danger, 1), 13%);    
  }
  &:active {    
    background-color: lighten(rgba($color_danger, 1), 19%);    
  }
}