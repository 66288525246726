@import '../../variables';

.wrapper {
  background: #FCF9F7;

  .header {    
    padding: 3rem 0 1rem; text-align: center;
    .fsSmall { font-size: 12vw;}
    .headerBrand { font-size: 16vw; padding: 0 0 .5rem; }
    .headerDate { font-size: 10vw; color: var(--color-secondary);}
  }   
  @media (min-width: 992px) { 
    .header {  
      padding: 2rem 0 1rem; text-align: center; 
      .fsSmall { font-size: 2.5rem; }
      .headerBrand { font-size: 4rem; }
      .headerDate { font-size: 3.25rem; }
    } 
  } 

  .body { padding: 0 0 2rem 0; }
  .footer {display: flex; flex-direction: column; justify-content: center; align-items: center; font-size: .75rem; padding: 2rem 0; }
  .separator {
    display: flex; justify-content: center; align-items: center; margin: 1rem 0;
    svg { width: 30%; max-width: 200px; }

    @media (min-width: 992px) { 
      svg { width: 200px; }
    }
  }

  .itineraryItems {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .itineraryItem { padding-top: var(--size-3); display: flex;  justify-content: center; text-align: center;}
    @media (min-width: 992px) { 
      flex-direction: row;
      .itineraryItem { flex-grow: 1; width: 100%;}
    }
  }
  
  .boxTags {
    padding: 15px; display: flex; flex-wrap: wrap; gap: 15px; justify-content: center;
    .boxTag { display: inline-block; padding: 5px 8px; border-radius: 5px;}
  }
}

.hero1 {
  background: #000;
  padding: .25rem;
  gap: .25rem!important;
  align-items: center;

  img {
    filter: grayscale(1)
  }
}

.mainGrid {
  gap: 1rem!important;
  align-items: center;

  @media (max-width: 992px) { 
    &.reversedGrid {
      div:first-child { grid-row: 2;}
      div:last-child { grid-row: 1;}
    }
  }
}

.stampEdge {
  padding: 10px;
  background-color: #fff;
  border: 2px solid #000;
}

.rsvpBox {
  position: relative;
  background: #edcda9;
  padding: 1rem .75rem;

  &:after {
    content: '';
    position: absolute;
    z-index: 0;
    top: .5rem;
    left: .5rem;
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    border: 3px solid #fff;
  }

  @media (min-width: 992px) { 
    padding: 2rem 1.5rem;
    &:after {
      top: 1rem;
      left: 1rem;
      width: calc(100% - 2rem);
      height: calc(100% - 2rem);
    }
  }

}

.rsvpCards {  
  display: flex;
  flex-direction: column;
  gap: var(--size-3);
  margin-top: var(--size-3);
  margin-bottom: var(--size-3);
  .rsvpCard {
    background-color: var(--color-body-secondary, #fff);
    border: 1px solid var(--color-body);
    border-radius: var(--size-3);
    padding: var(--size-3);
  }
}