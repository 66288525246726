.loadingWheel {
  display: inline-flex;
  position: relative;
  width: 100%;
  align-items:center;
  justify-content:center;  
  margin: 2px;
  height: 30px;
  width: 30px;
  div{
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid var(--color-primary, #000);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--color-primary, #000) transparent transparent transparent;
  }

  &.light {
    div {
      border-color: var(--color-body-secondary, #fff) transparent transparent transparent;
    }
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }
   div:nth-child(2) {
    animation-delay: -0.3s;
  }
   div:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes lds-ring {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}