.gridContainer {
  display: grid;
  grid-template-columns: repeat(10, 1fr); 
  grid-gap: 5px;

  &[data-columns="8"]{ grid-template-columns: repeat(8, 1fr);}
  &[data-columns="10"]{ grid-template-columns: repeat(10, 1fr);}
  &[data-columns="12"]{ grid-template-columns: repeat(12, 1fr);}

  @media (min-width: 992px) {     
    grid-gap: 32px;
  }

  .gridItem {    
    grid-column-start: var(--grid-item-start,1);
    grid-column-end: var(--grid-item-end,13);

    &[data-start="1"]{ grid-column-start: var(--grid-item-start,1);}
    &[data-start="2"]{ grid-column-start: var(--grid-item-start,2);}
    &[data-start="3"]{ grid-column-start: var(--grid-item-start,3);}
    &[data-start="4"]{ grid-column-start: var(--grid-item-start,4);}
    &[data-start="5"]{ grid-column-start: var(--grid-item-start,5);}
    &[data-start="6"]{ grid-column-start: var(--grid-item-start,6);}
    &[data-start="7"]{ grid-column-start: var(--grid-item-start,7);}
    &[data-start="8"]{ grid-column-start: var(--grid-item-start,8);}
    &[data-start="9"]{ grid-column-start: var(--grid-item-start,9);}
    &[data-start="10"]{ grid-column-start: var(--grid-item-start,10);}
    &[data-start="11"]{ grid-column-start: var(--grid-item-start,11);}
    &[data-start="12"]{ grid-column-start: var(--grid-item-start,12);}

    &[data-end="1"]{ grid-column-end: var(--grid-item-end,1);}
    &[data-end="2"]{ grid-column-end: var(--grid-item-end,2);}
    &[data-end="3"]{ grid-column-end: var(--grid-item-end,3);}
    &[data-end="4"]{ grid-column-end: var(--grid-item-end,4);}
    &[data-end="5"]{ grid-column-end: var(--grid-item-end,5);}
    &[data-end="6"]{ grid-column-end: var(--grid-item-end,6);}
    &[data-end="7"]{ grid-column-end: var(--grid-item-end,7);}
    &[data-end="8"]{ grid-column-end: var(--grid-item-end,8);}
    &[data-end="9"]{ grid-column-end: var(--grid-item-end,9);}
    &[data-end="10"]{ grid-column-end: var(--grid-item-end,10);}
    &[data-end="11"]{ grid-column-end: var(--grid-item-end,11);}
    &[data-end="12"]{ grid-column-end: var(--grid-item-end,12);}
    &[data-end="13"]{ grid-column-end: var(--grid-item-end,13);}


    @media (min-width: 992px) {    
      grid-column-start: var(--grid-item-lg-start,1);
      grid-column-end: var(--grid-item-lg-end,13);

      &[data-lg-start="1"]{ grid-column-start: var(--grid-item-lg-start,1);}
      &[data-lg-start="2"]{ grid-column-start: var(--grid-item-lg-start,2);}
      &[data-lg-start="3"]{ grid-column-start: var(--grid-item-lg-start,3);}
      &[data-lg-start="4"]{ grid-column-start: var(--grid-item-lg-start,4);}
      &[data-lg-start="5"]{ grid-column-start: var(--grid-item-lg-start,5);}
      &[data-lg-start="6"]{ grid-column-start: var(--grid-item-lg-start,6);}
      &[data-lg-start="7"]{ grid-column-start: var(--grid-item-lg-start,7);}
      &[data-lg-start="8"]{ grid-column-start: var(--grid-item-lg-start,8);}
      &[data-lg-start="9"]{ grid-column-start: var(--grid-item-lg-start,9);}
      &[data-lg-start="10"]{ grid-column-start: var(--grid-item-lg-start,10);}
      &[data-lg-start="11"]{ grid-column-start: var(--grid-item-lg-start,11);}
      &[data-lg-start="12"]{ grid-column-start: var(--grid-item-lg-start,12);}
  
      &[data-lg-end="1"]{ grid-column-end: var(--grid-item-lg-end,1);}
      &[data-lg-end="2"]{ grid-column-end: var(--grid-item-lg-end,2);}
      &[data-lg-end="3"]{ grid-column-end: var(--grid-item-lg-end,3);}
      &[data-lg-end="4"]{ grid-column-end: var(--grid-item-lg-end,4);}
      &[data-lg-end="5"]{ grid-column-end: var(--grid-item-lg-end,5);}
      &[data-lg-end="6"]{ grid-column-end: var(--grid-item-lg-end,6);}
      &[data-lg-end="7"]{ grid-column-end: var(--grid-item-lg-end,7);}
      &[data-lg-end="8"]{ grid-column-end: var(--grid-item-lg-end,8);}
      &[data-lg-end="9"]{ grid-column-end: var(--grid-item-lg-end,9);}
      &[data-lg-end="10"]{ grid-column-end: var(--grid-item-lg-end,10);}
      &[data-lg-end="11"]{ grid-column-end: var(--grid-item-lg-end,11);}
      &[data-lg-end="12"]{ grid-column-end: var(--grid-item-lg-end,12);}
      &[data-lg-end="13"]{ grid-column-end: var(--grid-item-lg-end,13);}
    }  
  }
}