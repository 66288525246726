.carousel {
  position: relative;  
  max-width: 100%;

  .carouselInner {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: visible!important;
    
  }

  .toggles {
    position: relative;
    z-index: 3;      
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    gap: 12.5px;
    
    .togglePrev, 
    .toggleNext {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border: 1px solid #6c7482;
      border-radius: 100%; 
      user-select: none;     
    }
    .togglePrev svg {
      transform: rotate(-180deg);
    }

    &.hideToggles {
      opacity: 0;
    }

    &[class~="topcenter"], &[class~="topspaced"] {
      margin-bottom: 15px;
      margin-top: 0;
    }
  }



  .toggles[class~="bottomspaced"], .toggles[class~="topspaced"] { justify-content: space-between; }
  .toggles[class~="bottomcenter"], .toggles[class~="topcenter"] { justify-content: center; }
  .toggles[class~="centercenter"] { position: absolute; top: 50%; left: 0; right: 0; transform: translateY(-50%); justify-content: space-between; margin: 0; height: 0; overflow: visible; }

  .carouselItem { 
    padding: 0 12.5px 0;
    flex: 0 0 100%; 
  } 

  .carouselMoveLeft .carouselItem :local {animation: moveleft .5s linear 1 forwards; }
  .carouselMoveRight .carouselItem :local {animation: moveright .5s linear 1 forwards;}   

  @media (min-width: 992px) { 
    .carouselInner {
      &[data-columns="1"] .carouselItem { flex: 0 0 100%; }
      &[data-columns="2"] .carouselItem { flex: 0 0 50%; }
      &[data-columns="3"] .carouselItem { flex: 0 0 33.33%; }
      &[data-columns="4"] .carouselItem { flex: 0 0 25%; }
      &[data-columns="5"] .carouselItem { flex: 0 0 20%; }
      &[data-columns="6"] .carouselItem { flex: 0 0 16.66%; }
      &[data-columns="7"] .carouselItem { flex: 0 0 14.28%; }
      &[data-columns="8"] .carouselItem { flex: 0 0 12.5%; }
      &[data-columns="9"] .carouselItem { flex: 0 0 11.11%; }
      &[data-columns="10"] .carouselItem { flex: 0 0 10%; }
      &[data-columns="11"] .carouselItem { flex: 0 0 9.09%; }
      &[data-columns="12"] .carouselItem { flex: 0 0 8.33%; }
    }      
  }

  @media (max-width: 991px) {       
    .carouselInner {
      .carouselItem { flex: 0 0 50%; }
    }
  }
}

@keyframes moveleft {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}


@keyframes moveright {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0); }
}