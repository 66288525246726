.button { position: relative; z-index: 2; outline: none; border: none; cursor: pointer; width: 35px; height: 25px; border-radius: 50%; background: transparent;}

.navWrapper {
  position: fixed; z-index: 1042; top: 0; left: 0; padding: 15px; display: block; text-align: left;
  .emblem {flex: 0 0 auto; display: inline-block; padding: .585rem .3rem .585rem .5rem; text-align: center; color: var(--color-body); border: 1px solid var(--color-body); border-radius: 100%; }

  ul {
    width: 100%; padding: 15px; position: absolute; display: flex; flex-direction: column; list-style: none;
    li {
      list-style: none; margin-bottom: 20px;       
      a {color: #fff;}
    }
  }

  @media (min-width: 992px) { 
    display: none;
  }
}

.navBackground { background: var(--color-secondary); color: #fff; position: fixed; z-index: 1; top: 0; left: 0; bottom: 0; width: 300px; max-width: 95%; padding-top: 65px; box-shadow: 0 1px 1px #222; overflow: hidden; }