@font-face {
  font-family:'Goldenbook';
  src: url('./fonts/goldenbook.eot');
  src: url('./fonts/goldenbook.eot?#iefix') format('embedded-opentype'),
  url('./fonts/goldenbook.woff2') format('woff2'),
  url('./fonts/goldenbook.woff') format('woff'),
  url('./fonts/goldenbook.ttf') format('truetype'),
  url('./fonts/goldenbook.svg#goldenbook') format('svg');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Goldenbook UP';
  src: url('./fonts/goldenbook-up.eot');
  src: url('./fonts/goldenbook-up.eot?#iefix') format('embedded-opentype'),
  url('./fonts/goldenbook-up.woff2') format('woff2'),
  url('./fonts/goldenbook-up.woff') format('woff'),
  url('./fonts/goldenbook-up.ttf') format('truetype'),
  url('./fonts/goldenbook-up.svg#goldenbook-up') format('svg');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'P22ZanerFive';
  src: url('./fonts/P22ZanerFive.eot');
  src: url('./fonts/P22ZanerFive.eot?#iefix') format('embedded-opentype'),
  url('./fonts/P22ZanerFive.woff2') format('woff2'),
  url('./fonts/P22ZanerFive.woff') format('woff'),
  url('./fonts/P22ZanerFive.ttf') format('truetype'),
  url('./fonts/P22ZanerFive.svg#P22ZanerFive') format('svg');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  unicode-range: U+0020-02DC;
}

@import './variables';

:root {
  --color-primary: #{$color_primary};
  --color-secondary: #{$color_secondary};
  --color-white: #{$color_white};
  --color-success: #{$color_success};
  --color-danger: #{$color_danger};
  --color-wine: #{$color_wine};
  --color-body: #{$color_body};
  --color-body-secondary: #{$color_body_secondary};

  --size-1: #{$size_1};
  --size-2: #{$size_2};
  --size-3: #{$size_3};
  --size-4: #{$size_4};
  --size-5: #{$size_5};
  --size-6: #{$size_6};
  --size-7: #{$size_7};
  --size-8: #{$size_8};
  --size-9: #{$size_9};
  --size-10: #{$size_10};

  --body-font-size: 26px;
  --rounded: #{$size_2};
  --rounded-lg: #{$size_4};
}


html, body { 
  margin: 0; 
  padding: 0; 
  scroll-behavior: smooth; 
  height: 100%; 
  min-height: 100%; 
  font-size: var(--body-font-size); 
  line-height: 1; 
}

body {
  font-family: "Goldenbook", serif;
  color: var(--color-body);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: var(--size-3); 
  @media (min-width: 992px) {     
    font-size: var(--body-font-size); 
  }
}


* {
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  overflow: visible;  
  margin: 0;
  padding: 0;
}

*:focus-visible {
  outline: none !important;
}

:global {
  #root {min-height: 100%; display: flex; flex-direction: column; flex-grow: 1;}


  h1, h2, h3 {
    font-family: "Goldenbook UP", serif; 
    margin-top: 0;
    margin-bottom: 15px;
    font-weight: 500;
  }

  h4, h5 {
    font-family: "Goldenbook UP", serif; 
    margin-top: 0;
    margin-bottom: 15px;
    font-weight: 600;
  }

  h1 {font-size: 3.5rem;}
  h2 {font-size: 2.5rem;}
  h3 {font-size: 1.75rem;}
  h4 {font-size: 1.25rem;}
  h5 {font-size: 1rem;}

  a, a:visited {
    color: $color_wine;
    text-decoration: underline;
  }

  a:hover {
    color: darken($color_primary, 20%);
    text-decoration: underline;
  }

  p {
    margin-bottom: 15px;
  }

  ul {
    padding-left: 20px;
  }
  ul li {
    margin-bottom: 15px;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  hr {
    margin-top: var(--size-3); 
    margin-bottom: var(--size-3);
  }

  textarea,input,select, label, button {
    font-family: "Goldenbook", serif;
    font-size: var(--body-font-size, 16px);
  }

  .ff-goldenbook-up {font-family: "Goldenbook UP", serif;}
  .ff-p22zaner {font-family: "P22ZanerFive", cursive;}
  .text-color-primary {color: var(--color-primary);}
  .text-color-secondary {color: var(--color-secondary);}
  .text-color-success {color: var(--color-success);}
  .text-color-danger {color: var(--color-danger);}

  .fs-huge {font-size: 6rem;}
  .fs-large {font-size: 3rem;}
  .fs-medium {font-size: 2rem;}
  .fs-normal {font-size: 1rem;}
  .fs-small {font-size: .875rem;}

  .fw-300 {font-weight: 300;}
  .fw-400 {font-weight: 400;}
  .fw-500 {font-weight: 500;}
  .fw-600 {font-weight: 600;}
  .fw-700 {font-weight: 700;}
  .fw-800 {font-weight: 800;}


  .p-1 {padding: var(--size-1);}
  .p-2 {padding: var(--size-2);}
  .p-3 {padding: var(--size-3);}
  .p-4 {padding: var(--size-4);}
  .p-5 {padding: var(--size-5);}

  .py-1 {padding-top: var(--size-1); padding-bottom: var(--size-1);}
  .py-2 {padding-top: var(--size-2); padding-bottom: var(--size-2);}
  .py-3 {padding-top: var(--size-3); padding-bottom: var(--size-3);}
  .py-4 {padding-top: var(--size-4); padding-bottom: var(--size-4);}
  .py-5 {padding-top: var(--size-5); padding-bottom: var(--size-5);}

  .pt-1 {padding-top: var(--size-1);}
  .pt-2 {padding-top: var(--size-2);}
  .pt-3 {padding-top: var(--size-3);}
  .pt-4 {padding-top: var(--size-4);}
  .pt-5 {padding-top: var(--size-5);}

  .pb-1 {padding-bottom: var(--size-1);}
  .pb-2 {padding-bottom: var(--size-2);}
  .pb-3 {padding-bottom: var(--size-3);}
  .pb-4 {padding-bottom: var(--size-4);}
  .pb-5 {padding-bottom: var(--size-5);}

  .px-1 {padding-left: var(--size-1); padding-right: var(--size-1);}
  .px-2 {padding-left: var(--size-2); padding-right: var(--size-2);}
  .px-3 {padding-left: var(--size-3); padding-right: var(--size-3);}
  .px-4 {padding-left: var(--size-4); padding-right: var(--size-4);}
  .px-5 {padding-left: var(--size-5); padding-right: var(--size-5);}

  .pl-1 {padding-left: var(--size-1);}
  .pl-2 {padding-left: var(--size-2);}
  .pl-3 {padding-left: var(--size-3);}
  .pl-4 {padding-left: var(--size-4);}
  .pl-5 {padding-left: var(--size-5);}

  .pr-1 {padding-right: var(--size-1);}
  .pr-2 {padding-right: var(--size-2);}
  .pr-3 {padding-right: var(--size-3);}
  .pr-4 {padding-right: var(--size-4);}
  .pr-5 {padding-right: var(--size-5);}

  .m-1 {margin: var(--size-1);}
  .m-2 {margin: var(--size-2);}
  .m-3 {margin: var(--size-3);}
  .m-4 {margin: var(--size-4);}
  .m-5 {margin: var(--size-5);}

  .my-1 {margin-top: var(--size-1); margin-bottom: var(--size-1);}
  .my-2 {margin-top: var(--size-2); margin-bottom: var(--size-2);}
  .my-3 {margin-top: var(--size-3); margin-bottom: var(--size-3);}
  .my-4 {margin-top: var(--size-4); margin-bottom: var(--size-4);}
  .my-5 {margin-top: var(--size-5); margin-bottom: var(--size-5);}

  .mt-1 {margin-top: var(--size-1);}
  .mt-2 {margin-top: var(--size-2);}
  .mt-3 {margin-top: var(--size-3);}
  .mt-4 {margin-top: var(--size-4);}
  .mt-5 {margin-top: var(--size-5);}

  .mb-1 {margin-bottom: var(--size-1);}
  .mb-2 {margin-bottom: var(--size-2);}
  .mb-3 {margin-bottom: var(--size-3);}
  .mb-4 {margin-bottom: var(--size-4);}
  .mb-5 {margin-bottom: var(--size-5);}

  .mx-1 {margin-left: var(--size-1); margin-right: var(--size-1);}
  .mx-2 {margin-left: var(--size-2); margin-right: var(--size-2);}
  .mx-3 {margin-left: var(--size-3); margin-right: var(--size-3);}
  .mx-4 {margin-left: var(--size-4); margin-right: var(--size-4);}
  .mx-5 {margin-left: var(--size-5); margin-right: var(--size-5);}

  .ml-1 {margin-left: var(--size-1);}
  .ml-2 {margin-left: var(--size-2);}
  .ml-3 {margin-left: var(--size-3);}
  .ml-4 {margin-left: var(--size-4);}
  .ml-5 {margin-left: var(--size-5);}

  .mr-1 {margin-right: var(--size-1);}
  .mr-2 {margin-right: var(--size-2);}
  .mr-3 {margin-right: var(--size-3);}
  .mr-4 {margin-right: var(--size-4);}
  .mr-5 {margin-right: var(--size-5);}

  .text-center {text-align: center;}
  .text-left {text-align: left;}
  .text-right {text-align: right;}

  .text-no-wrap {text-wrap: nowrap!important;}
  .text-underline {text-decoration: underline!important;}

  .d-flex {display: flex}
  .d-block {display: block;}

  .flex-wrap {flex-wrap: wrap;}

  .justify-content-center {justify-content: center;}
  .justify-content-end {justify-content: flex-end;}
  .justify-content-start {justify-content: flex-start;}
  .justify-content-between {justify-content: space-between;}

  .align-items-center {align-items: center;}
  .align-items-end {align-items: flex-end;}
  .align-items-start {align-items: flex-start;}

  .gap-1 {gap: var(--size-1);}
  .gap-2 {gap: var(--size-2);}
  .gap-3 {gap: var(--size-3);}
  .gap-4 {gap: var(--size-4);}
  .gap-5 {gap: var(--size-5);}

  .container {
    z-index: 1;
    display: block flow-root;
    max-width: 1476px;
    width: 100%;
    margin: 0 auto;  
    padding-left: 15px;
    padding-right: 15px;
    @media (min-width: 992px) { 
      padding-left: 32px; 
      padding-right: 32px;
    }
  
  }
}