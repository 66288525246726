.wrapper {
  display: flex; flex-grow: 1; flex-direction: column; justify-content: center; align-items: center; text-align: center; padding-top: 1rem; padding-bottom: 1rem;
  
  h1,h3 { font-size: 11vw;}

  @media (min-width: 992px) {
    h1,h3 { font-size: 4rem; }
  }
}

.container {display: flex; flex-grow: 1; flex-direction: column; justify-content: center; align-items: center; position: relative; z-index: 0; overflow: hidden;}

.chateauWrap {
  display: flex; flex-direction: column; justify-content: center; align-items: center; width: 600px; max-width: 100%;

  .svgChange {
    width: 100%;

    svg path:nth-child(5n + 1) { animation: 4s linear 0s infinite normal none running opacityChange;}
    svg path:nth-child(5n + 2) { animation: 2.5s linear .25s infinite normal none running opacityChange; }
    svg path:nth-child(5n + 3) { animation: 2.5s linear .5s infinite normal none running opacityChange; }
    svg path:nth-child(5n + 4) { animation: 2.5s linear .75s infinite normal none running opacityChange; }
    svg path:nth-child(5n) { animation: 2.5s linear 1s infinite normal none running opacityChange; }
  }
}

.loginBox {text-align : center; width: 500px; max-width : 100%; margin: 3rem auto 0;}
.alertWrap {display: flex; justify-content: center; align-items: center; font-size: 1rem;}

.loadingScreen {
  background-color: rgba(255,255,255,.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;

  & div {
    position: absolute; 
    opacity: 0;
    text-wrap: nowrap;
    padding: 1em;
    animation: 4s linear 0s infinite zoomDissapear;
    color: #211a15;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & div:nth-child(1) {top: 5%; left: 35%; font-size: .85em;}
  & div:nth-child(2) {top: 15%; left: 0; animation-delay: .5s;}
  & div:nth-child(3) {top: 25%; right: 10%; animation-delay: 1s;}
  & div:nth-child(4) {bottom: 15%; left: 15%; font-size: 1.2em; animation-delay: 1.5s;}
  & div:nth-child(5) {bottom: 2%; right: 15%; font-size: 1.2em; animation-delay: 2s;}
  & div:nth-child(6) {top: 30%; right: 0%; font-size: 2em; animation-delay: 2.5s; width: 100%;}

  @media (min-width: 992px) {
    font-size: 3vw;
    & div:nth-child(1) {top: 5%; right: 50%;}
    & div:nth-child(2) {top: 10%; left: 0;}
    & div:nth-child(3) {top: 20%; right: 10%;}
    & div:nth-child(4) {bottom: 12%; left: 10%;}
    & div:nth-child(5) {bottom: 6%; right: 15%;}
    & div:nth-child(6) {top: 20%; right: 0%;}
  }
}

@keyframes opacityChange {
  0% { opacity: 1; }  
  50% { opacity: 0; }
  100% { opacity: 1; }  
}

@keyframes zoomDissapear {
  0% { opacity: 0; transform: scale(1); }  
  20% { opacity: 1; transform: scale(1); }  
  100% { opacity: 0; transform: scale(0); }  
}