.carousel {
  position: relative;  
  max-width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;


  .carouselInner {
    position: relative;
  }

  .toggles {
    position: relative;
    z-index: 3;      
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    gap: 12.5px;
    
    .togglePrev, 
    .toggleNext {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border: 1px solid #6c7482;
      border-radius: 100%;      
      user-select: none;
    }
    .togglePrev svg {
      transform: rotate(-180deg);
    }

    &[class~="topcenter"], &[class~="topspaced"] {
      margin-bottom: 15px;
      margin-top: 0;
    }
  }

  .carouselItem {
    transition: transform .5s ease-in-out;
    &:not(:first-child){
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
    &:nth-child(2n) { transform: rotate(5deg); }
    &:nth-child(3n) { transform: rotate(-5deg); }
    &:nth-child(4n) { transform: rotate(10deg); }
    &:nth-child(5n) { transform: rotate(-10deg); }
    &:first-child { position: relative; z-index: 3;}
  }

  .toggles[class~="bottomspaced"], .toggles[class~="topspaced"] { justify-content: space-between; }
  .toggles[class~="bottomcenter"], .toggles[class~="topcenter"] { justify-content: center; }
  .toggles[class~="centercenter"] { position: absolute; top: 50%; left: 0; right: 0; transform: translateY(-50%); justify-content: space-between; margin: 0; height: 0; overflow: visible; }

  @media (max-width: 991px) {     
    //.toggles {opacity: 0;}
  }
}