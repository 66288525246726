.inputWrap {
  display: flex;
  flex-direction: column;
  position:relative;
  width: 100%;
  min-width: 175px;
  margin-bottom: 1rem;
  text-align: left;
  z-index: 2;

  :global {
    .react-select__control { 
      width: 100%;
      border: 1px solid var(--color-body, #000)!important;
      box-shadow: none!important;
      color:var(--color-body, #000);  
      & .react-select__value-container {
        flex-wrap: wrap;
      }      
      & .react-select__multi-value {
        align-items: center;        
        background-color: var(--color-wine);
        & .react-select__multi-value__label {
          color: var(--color-body-secondary)!important;
        }
      }
      & .react-select__indicator {
        padding: 13px 15px;      
      }  
      & .react-select__indicator:hover svg {
        cursor: pointer;
        fill: var(--color-body, #000);
        & path {
          fill: var(--color-body, #000);
        }
      }      
    }
  } 

  &[class~="error-input"] input {
    box-shadow: 0px 0px 0px 1px var(--color-danger);
  }

  &[class~="input-lg"] input {
    font-size: 1.35rem;    
    padding: 14px 30px;
  }

  &[class~="input-lg"] :local {
    .error {
      padding: 3px 30px;
    }
  }
}

.labelWrap {
  margin-bottom: 10px;  
  small {
    font-style: italic; font-size: .75rem;
  }
  label {
    margin-bottom: 0;
    display: flex;
    font-weight: 500;
    font-size: .875rem;
  }
}

.error {
  padding: 3px 14px; 
  color: var(--color-danger);
  font-weight: 500;
  line-height: 1;
}