.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;  
  border-radius: 8px;
  width: 900px;
  max-width: 90%;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;  
}

.popup-body {
  padding: 0 20px 20px;
  max-height: 80vh;
  overflow: auto;
}

.popup-header {
  display: flex;
  padding: 10px;
  .popup-close {
    padding: 5px;
    margin-left: auto;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
}